.dea-page-content {
  @include media("<m") {
    margin-left: 0;
    padding-left: $gutter-half;
    padding-right: $gutter-half;
    width: 100%;
  }

  > * + * {
    margin-top: $space-large;
  }

  margin-bottom: $space-x-large - $space-base;
  width: 100%;

  .w-text,
  .w-dea-speakers {
    padding: 0 $gutter-half;
  }

  .w-section {
    > * + * {
      margin-top: $space-base;
    }
  }

  .w-dea-gallery {
    padding: 0 $gutter-half;

    &__container {
      @include media("<=m") {
        content: "";
        flex: 0 0 48%;
      }
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &:after {
        content: "";
        flex: 0 0 31%;
      }
    }

    &__image {
      @include media(">m") {
        &:nth-child(n + 4) {
          margin-top: $space-base;
        }
        flex: 0 0 31%;
      }
      @include media("<=m") {
        &:nth-child(n + 3) {
          margin-top: $space-base / 2;
        }
        flex: 0 0 48%;
      }
      @include media("<=xs") {
        flex: 0 0 100%;
        margin-top: $space-base / 2;
      }

      &:nth-child(n + 4) {
        display: none;
      }
      align-self: center;

      img {
        height: auto;
        width: 100%;
      }
    }

    &__see-all-container {
      margin-top: $space-small;
      text-align: right;
    }

    &__see-all-link {
      .dea-links__icon {
        font-size: $dea-size-small;
        transform: translateY($space-xx-small);
      }
    }
  }
}

.dea-hero {
  margin-bottom: $space-large;

  &:before {
    display: none;
  }
}

.section-event-styles {
  @include title-with-border();
  @include stylize-event($dea-color-secondary-magenta);
}

.w-card__img {
  height: auto;
  max-width: 100%;
}

.dea-footer-top {
  margin-top: $space-large;
}

.w-dea-show-more-plugin {
  .dea-links {
    &.dea-links--show-more {
      .dea-links__label {
        border-color: $dea-color-target-magenta-formazione;
      }

      .dea-links__icon:before {
        color: $dea-color-target-magenta-formazione;
      }
    }
  }

  .w-dea-show-more-plugin__content.active {
    border-color: $dea-color-target-magenta-formazione;
  }
}
