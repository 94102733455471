$dea-lancio--height-s: 200px;

.dea-search {
  header {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .dea-links__label {
      border-color: $dea-color-target-magenta-formazione;
    }
  }

  .dea-lancio--2-12,
  .dea-lancio--7-12,
  .dea-lancio--6-12,
  .dea-lancio--10-12,
  .dea-lancio--12-12,
  .dea-lancio--3-12 {
    margin-left: $gutter-half !important; //sass-lint:disable-line no-important
    margin-right: $gutter-half !important; //sass-lint:disable-line no-important
  }
}

.wrapper-content {
  .grid {
    width: 100%;
  }
}

section {
  &.w-dea-launchsection-plugin {
    padding: 0;

    .w-dea-launchsection-plugin__launches {
      @include media("<m") {
        padding: 0;
      }
    }

    .launches {
      @include media("<m") {
        > * {
          margin-bottom: 0;

          & + * {
            margin-top: $space-large;
          }
        }
      }
      margin-bottom: 0;

      .w-embedded-video {
        flex-direction: column;

        &.dea-lancio--size-xs,
        &.dea-lancio--size-s,
        &.dea-lancio--size-m,
        &.dea-lancio--size-l,
        &.dea-lancio--size-xl {
          @include media("<m") {
            display: block;
            margin-bottom: 0;
            width: 100%;
          }
          display: flex;
          height: auto;
          margin-bottom: $space-base;

          .w-embedded-video__iframe-container {
            @include media("<m") {
              height: 0;
              padding-bottom: 56.25%; // 16:9 ratio
              position: relative;
            }
            flex: 1 0 auto;
            height: auto;
            padding-bottom: 0;

            iframe {
              @include media("<m") {
                left: 0;
                position: absolute;
                top: 0;
              }
              height: 100%;
              position: relative;
              width: 100%;
            }
          }
        }

        &.dea-lancio--size-x {
          @include media("<m") {
            display: block;
            margin-bottom: 0;
            width: 100%;
          }
        }
      }

      .w-video {
        display: flex;
        height: auto;
        margin-bottom: $space-base;

        .video-js {
          background-color: transparent;
          flex: 1 0 auto;
          height: auto;
          padding-bottom: 0;
          position: relative;
        }
      }
    }
  }
}

.event-detail .event-content,
.dea-page-content {
  .dea-work-launch {
    @include media("<=m") {
      width: 100%;
    }

    margin-bottom: 0;
    margin-right: 0;
    padding: 0 !important; //sass-lint:disable-line no-important

    .dea-lancio {
      &__inner {
        min-height: 350px;
        padding: $space-x-large;

        > * {
          @include media("<=m") {
            max-width: 100%;
          }

          margin-left: auto;
          max-width: 50%;
        }
      }

      &__content {
        margin-top: $space-small;
      }

      &__bg-image--bottom-right {
        left: 0;
        top: $space-x-large;
        width: 50%;

        &:after {
          background-image: none;
        }

        img {
          object-position: inherit;
        }
      }

      &__cta-container {
        margin-bottom: 0;
        margin-top: $space-large;
      }
    }

    & + * {
      @include media("<=m") {
        margin-top: $space-large;
      }

      margin-top: calc(#{$space-base} + #{$space-x-large});
    }

    &:after {
      @include media("<=m") {
        content: none;
      }

      background-position: top left;
      background-repeat: no-repeat;
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

.dea-page-content {
  .dea-work-launch {
    @include hmargin($gutter-half, $gutter-half);
    width: 100%;
  }

  .w-dea-launchsection-plugin {
    @include media("<=m") {
      @include hmargin($gutter-half, $gutter-half);
      padding: 0;
    }

    .launches {
      margin-left: 0;
      width: 100%;
    }
  }
}

.dea-lancio {
  &.dea-lancio--fullwidth {
    @include media("<m") {
      padding: $space-base;
    }
  }

  @media screen and (max-width: $bp-size-small-medium) {
    &.dea-lancio--mobile-picture {
      padding-bottom: 0 !important; //sass-lint:disable-line no-important

      .dea-lancio__bg-image {
        img {
          vertical-align: bottom;
        }

        &.dea-lancio__bg-image--bottom-right {
          margin-right: -$space-base !important; //sass-lint:disable-line no-important
        }
      }
    }
  }

  &.dea-lancio--height-s {
    @include media("<=m") {
      height: auto;
    }

    height: $dea-lancio--height-s;
  }
}
