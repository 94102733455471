.dea-error {
  align-items: center;
  display: flex;
  margin-left: $space-small;

  .dea-ico-error {
    background-color: $dea-color-feedback-red-negative;
    border-radius: 50%;
    color: $dea-color-white;
    font-family: $font-primary-bold;
    font-size: $font-size-small;
    margin-right: $space-x-small;
    padding: $space-xxx-small;

    &::before {
      vertical-align: middle;
    }
  }

  span {
    align-self: flex-end;
    color: $dea-color-feedback-red-negative;
    display: flex;
    margin: 0;

    a {
      margin-left: $space-xx-small;
    }
  }
}
