.site-footer {
  .dea-footer--bottom {
    @include bp-large-max {
      padding: 0 $space-small;
      padding-bottom: $space-base;
      padding-top: $space-base;
    }
    padding: 0 $space-x-large;
    padding-bottom: $space-small * 2;
    padding-top: $space-small * 2;

    &__copyright-container {
      margin-top: $space-small;
    }
  }

  .dea-footer--bottom__sub-links-container {
    @include media("<s") {
      flex-direction: column;
    }

    @include media("<=m") {
      flex-wrap: wrap;
    }

    display: flex;
    justify-content: space-around;
    padding-top: 0;

    .dea-footer--bottom__column-link {
      display: flex;
      margin-right: $space-base;
    }
  }
}
