/**
 * The default red.
 *
 * @color
 * @section Base Colors
 */
$red: #c00;

/**
 * The default black.
 *
 * @color
 * @section Base Colors
 */
$black: #262a31;

/**
 * The default white.
 *
 * @color
 * @section Base Colors
 */
$white: #fff;

/**
 * A shade of grey.
 *
 * @color
 * @section Base Colors
 */
$grey-d-3: #232221;

/**
 * A shade of grey.
 *
 * @color
 * @section Base Colors
 */
$grey-d-2: #333;

/**
 * A shade of grey.
 *
 * @color
 * @section Base Colors
 */
$grey-d-1: #636261;

/**
 * A shade of grey.
 *
 * @color
 * @section Base Colors
 */
$grey-n: #939291;

/**
 * A shade of grey.
 *
 * @color
 * @section Base Colors
 */
$grey-l-1: #a3a2a1;

/**
 * A shade of grey.
 *
 * @color
 * @section Base Colors
 */
$grey-l-2: #c3c2c1;

/**
 * A shade of grey.
 *
 * @color
 * @section Base Colors
 */
$grey-l-3: #e3e2e1;

/**
 * A shade of grey.
 *
 * @color
 * @section Base Colors
 */
$grey-l-4: #f4f4f4;

/**
 * A shade of grey.
 *
 * @color
 * @section Base Colors
 */
$grey-l-5: #fafafa;

/**
 * A very boring yellow (this is just an example).
 *
 * @color
 * @section Project Colors
 */
$egg: #f60;

/**
 * A shade of blue
 *
 * @color
 * @section Project Colors
 */
$blue: #78c0e0;

/**
 * A darker shade of blue
 *
 * @color
 * @section Project Colors
 */
$dark-blue: #449dd1;

/**
 * The primary font. Usually we use only two different fonts
 *
 * @nuclide Main font family
 * @section Settings > Fonts
 */
$main-font: "Lato";

/**
 * The secondary font. Usually we use only two different fonts
 *
 * @nuclide Secondary font family
 * @section Settings > Fonts
 */
$secondary-font: "LatoBold";

/**
 * The main line-height. You will rarely need to use this variable.
 *
 * @nuclide Main line height
 * @section Settings > Fonts
 */
$line-height: 1.6;

/**
 * The line-height we use for headings.
 *
 * @nuclide Headings line height
 * @section Settings > Fonts
 */
$line-height-headings: 1.1;

/**
 * The spacing we use for elements.
 * Use this to space elements inside plugins ecc. on a per-project basis.
 * Use variants with sensible names, please.
 *
 * @nuclide Spacing variable
 * @section Settings > Aria
 */
$aria: 16px;
// Some examples
$aria-double: $aria * 2;
// $aria-percent: 8%;
// $aria-percent-double: $aria-percent * 2;

// Private
// Fonts - Heading sizes
$title-size-1: 48px;
$title-size-2: 42px;
$title-size-3: 32px;
$title-size-4: 28px;
$title-size-5: 18px;
$title-size-6: 12px;

//Font weights
$bold: 700;

//tag box
$tag-border: 1px solid $egg;
$tag-left-radius: 5px;
$tag-right-radius: 2px;

//tag arrow
$arrow-bottom: 13px solid transparent;
$arrow-left: 13px solid $egg;
$arrow-top: 14px solid transparent;
$arrow-left-position: 100%;

//header badge
$badge-width: 100px;

//header video
$header-video: 400px;

//speakers slider
$card-size: 165px;
$speaker-picture-size: 75px;
$testimonial-picture-size: 120px;
$tooltip-width-desktop: 442px;
$tooltip-width-tablet: 342px;
$tooltip-width-phone: 200px;

$sidebar-width: 282px;
$sidebar-width-medium: 25%;
$header-height: 224px;

// FIXME: this breakpoint is already on storybook, with the "sm" name.
// When formazione will be ported to storybook, we can safely remove it
// and use @include media("<sm")
$bp-size-small-medium: 600px;
