.dea-button {
  &.dea-button--tight {
    padding: 11px $space-x-small;

    .dea-button__label {
      margin-left: -$space-base;
    }
  }

  .dea-button__icon {
    transform: translateY(4px) !important; //sass-lint:disable-line no-important
  }

  &--small {
    .dea-button__icon {
      transform: translateY(1px) !important; //sass-lint:disable-line no-important
    }
  }
}

.product-footer {
  padding-top: $space-x-large;
}

.site-footer {
  background-color: $dea-color-grey-medium-dark;
}

.dea-footer--mini {
  display: flex;

  .dea-footer--bottom__columns-container {
    border-bottom: 0;
    flex: 1;
  }
}
