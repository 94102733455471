.dea-banner-ie {
  bottom: 0;
  display: none;
  position: fixed;
  width: 100%;
  z-index: 999;

  p {
    background: rgba($dea-color-black, 0.9);
    color: $dea-color-white;
    margin: 0;
    padding: $space-base 0;
    text-align: center;
    width: 100%;
  }
}

//sass-lint:disable  no-vendor-prefixes
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .dea-banner-ie {
    display: flex;
  }
}

@supports (-ms-accelerator: true) {
  .dea-banner-ie {
    display: flex;
  }
}

@supports (-ms-ime-align: auto) {
  .dea-banner-ie {
    display: flex;
  }
}
//sass-lint:enable  no-vendor-prefixes
