.sidebar {
  @include media("<=l") {
    right: calc(50% - #{$grid-m / 2});
  }
  @include media("<=m") {
    right: calc(50% - #{$grid-s / 2});
    width: $sidebar-width-medium;
  }
  @include media("<=s") {
    border: 0;
    border-radius: 0;
    bottom: 0;
    box-shadow: 0 -2px 3px 0 rgba(0, 0, 0, 0.5);
    overflow: hidden;
    position: fixed;
    right: 0;
    top: auto;
    width: 100%;
    z-index: 1;
  }
  background: $dea-color-white;
  border: $border-light $dea-color-target-magenta-formazione;
  border-radius: $border-radius-large;
  position: absolute;
  right: calc(50% - #{$grid-xl / 2});
  top: $header-height + $dea-size-large + $space-large;
  width: $sidebar-width;

  hr {
    @include vmargin($space-small, $space-small);
  }

  &.js-sticky {
    position: fixed;
    top: $space-base + $dea-header-height;
  }

  &.js-sticky--bottom {
    position: absolute;
  }

  &__message {
    padding: 0 $space-x-small;
  }

  &__content {
    @include media("<=s") {
      display: none;
    }
    @include vmargin(0, $space-base);
    @include hmargin($space-x-small, $space-x-small);
    background: $dea-color-grey-light;
    padding: $space-x-small;

    &--faq-container h2 {
      font-weight: $font-weight-bold;
    }

    &--phone-container {
      p {
        margin-top: 0;
      }

      h2 {
        margin-bottom: $space-xx-small;
      }
    }

    &--contact-form-container {
      h2 {
        align-items: center;
        cursor: pointer;
        display: flex;

        span {
          margin-left: auto;

          &:before {
            font-size: $dea-size-medium;
          }
        }
      }

      .contact-form__form {
        display: none;
        margin: $space-small 0 0 0;

        &.active {
          display: block;
        }

        .dea-button {
          background-color: $dea-color-grey-dark;
        }
      }
    }

    &--agency-container {
      padding-bottom: $space-small;
    }

    .dea-button {
      background-color: $white;

      &.dea-button--small {
        @include media("<=l") {
          height: auto;
        }
      }
    }
  }

  &__location {
    @include media("<=s") {
      display: none;
    }
    padding: $space-small;

    h2 {
      margin: 0;
    }

    p {
      margin: $space-xx-small 0;
    }

    &--link {
      margin-bottom: $space-small;
      text-align: right;
    }

    &--duration,
    &--hours {
      font-family: $font-primary;
      font-size: $font-size-medium;
    }

    &--duration {
      display: flex;
      justify-content: space-between;
    }
  }

  &__next-legs-anchor {
    display: none;
    padding: $space-base $space-x-small 0 $space-x-small;
  }

  &__register {
    display: flex;
    flex-direction: column;
    padding: $space-base $space-x-small;

    &-action-container {
      @include media("<=m") {
        flex-direction: column;
      }
      display: flex;
      flex: 1 0 auto;
      flex-direction: row;

      &.sidebar__actions--go-to-home {
        flex-direction: column;

        .sidebar__actions {
          text-align: left;
        }
      }
    }

    .close-sidebar {
      display: none;
    }
  }

  &__actions {
    text-align: right;
    width: 100%;

    .dea-button {
      margin-bottom: $space-small;
    }

    .add-to-cart-tip {
      margin-bottom: $space-xx-small;
      text-align: left;
    }
  }

  &__bundles {
    padding: $space-x-small;
    text-align: left;

    p {
      color: $dea-color-target-magenta-formazione;
      margin: 0;
    }

    .sidebar__bundles--event-title {
      margin-top: $space-x-small;
    }

    .dea-links--discover-bundle {
      margin-top: $space-xx-small;
      text-align: right;

      span {
        @include dea-heading--regular__xm; // sass-lint:disable-line mixin-name-format
        color: $dea-color-grey-dark;
      }
    }
  }

  &__price {
    @include media("<=l") {
      flex-basis: 40%;
    }
    flex-basis: 35%;
    margin-right: $space-x-small;

    &-value {
      margin-top: $space-x-small;

      &--discounted {
        text-decoration: line-through;
      }
    }

    &-warning-message {
      @include vmargin($space-xx-small, $space-small);
    }
  }

  &__mobile {
    z-index: 99;

    > * {
      pointer-events: none;
    }

    .sidebar__register-action-container {
      @include media("<=m") {
        flex-direction: row;
      }

      &.sidebar__actions--go-to-home {
        flex-direction: column;

        .sidebar__actions {
          text-align: left;
        }
      }
    }

    .sidebar__price-value {
      @extend .dea-heading--medium__l;

      &--discounted {
        @extend .dea-heading--regular__s;
      }
    }

    .add-to-cart-button,
    .how-sign-up-works-link,
    .add-to-cart-tip,
    .sidebar__bundles {
      display: none;
    }

    .dea-button {
      margin-bottom: 0;
    }

    &.active {
      height: 100%;
      top: 0;

      > * {
        pointer-events: auto;
      }

      .close-sidebar {
        display: flex;
        margin-bottom: $space-large;
        margin-left: auto;
      }

      .add-to-cart-button {
        display: flex;
      }

      .how-sign-up-works-link {
        display: inline-block;
      }

      .add-to-cart-tip {
        display: block;
      }

      .sidebar__bundles {
        display: block;
      }

      .dea-button {
        margin-bottom: $space-base;
      }
    }
  }

  .dea-button--magenta {
    background-color: $dea-color-target-magenta-formazione;
    border-color: $dea-color-target-magenta-formazione;
  }
}
