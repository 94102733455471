/* Generic styles for overall look & feel */

//sass-lint:disable no-important
.dea-header--center__logo-formazione img {
  height: 47px;
  margin-right: $space-base;
  width: auto;
}

.bg-event-webinar {
  background-color: $dea-event-webinar;
}

.border-event-webinar {
  @include event-links--active($dea-event-webinar);
}

.bg-event-attended-event {
  background-color: $dea-event-attended-event;
}

.border-event-attended-event {
  @include event-links--active($dea-event-attended-event);
}

.bg-event-seminar {
  background-color: $dea-event-seminar;
}

.border-event-seminar {
  @include event-links--active($dea-event-seminar);
}

.bg-event-convention {
  background-color: $dea-event-convention;
}

.border-event-convention {
  @include event-links--active($dea-event-convention);
}

.bg-event-online-course {
  background-color: $dea-event-online-course;
}

.border-event-online-course {
  @include event-links--active($dea-event-online-course);
}

.bg-event-customizable-course {
  background-color: $dea-event-customizable-course;
}

.border-event-customizable-course {
  @include event-links--active($dea-event-customizable-course);
}

.bg-event-video-lesson {
  background-color: $dea-event-video-lesson;
}

.border-event-video-lesson {
  @include event-links--active($dea-event-video-lesson);
}

.bg-event-bundle {
  background-color: $dea-event-bundle;
}

.border-event-bundle {
  @include event-links--active($dea-event-bundle);
}

.dea-color-magenta-formazione {
  color: $dea-color-target-magenta-formazione;
}

.dea-links--color-magenta-formazione {
  .dea-links__label {
    border-color: $dea-color-target-magenta-formazione;
  }
}

.dea-border-target-color-magenta-formazione,
.dea-border-target-magenta-formazione {
  border-color: $dea-color-target-magenta-formazione !important;
}

.dea-bg-target-magenta-formazione {
  background-color: $dea-color-target-magenta-formazione !important;
}

.dea-list-catalog__main-list__item-active {
  .dea-border-target-color-magenta-formazione {
    border-left: $border-bold $dea-color-target-magenta-formazione !important;
  }
}

.dea-list-catalog__section-title {
  &.dea-border-target-color-magenta-formazione {
    border: 0 !important;
  }
}
//sass-lint:enable no-important
