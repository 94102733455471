$dea-color-target-magenta-formazione: $dea-color-secondary-magenta;

$dea-event-header-background: #ebeff5;
$dea-event-header-categories-background: #c8d1e0;
$dea-event-bundles-warning-background: #fde7ee;

$dea-header-height: 176px;
$dea-header-mobile-height: 142px;

$dea-subcategory-icon-width: 78px;
$dea-subcategory-content-color: #8c97a9;
$dea-subcategory-content-hover-color: #e9edf3;
$dea-subcategory-border-radius: 20px;
$dea-subcategory-icon-hover-color: #e71059;

$dea-product-input-box: 290px;

@mixin event-links--active($color: $dea-color-black) {
  border-left: $border-bold $color;
}
