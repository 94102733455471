$category-tile-size: ($grid-xl / 5) - $gutter;
$border-x-bold: 4px solid;

.categories-list {
  &__list {
    @include media("<=l") {
      justify-content: space-between;
    }
    @media (max-width: ($grid-xl / 5) * 2) {
      justify-content: center;
    }
  }

  &__header {
    h1 {
      @include media("<=m") {
        text-align: center;
      }
    }
  }

  &__input-search {
    margin-top: $space-base + $space-xxx-small;
    position: relative;

    i {
      cursor: pointer;
      position: absolute;
      right: $space-x-small;
      top: 50%;
      transform: translateY(-50%);
    }

    input {
      @include media("<=m") {
        width: 100%;
      }
      padding: $space-x-small;
      width: 50%;
    }
  }

  &__category-name {
    @include media("<=xs") {
      text-align: center;
    }

    border-bottom: 3px solid $dea-color-target-magenta-formazione;
  }

  &__item {
    border: $border-light $dea-subcategory-content-color;
    border-radius: $dea-subcategory-border-radius;
    color: $dea-subcategory-content-color;
    display: flex;
    flex-direction: column;
    height: $category-tile-size;
    justify-content: space-between;
    margin: 0 $gutter-half $space-small;
    padding: $space-small $gutter-half 0;
    position: relative;
    text-align: center;
    width: $category-tile-size;

    &-wrapper {
      @include hmargin($space-small, $space-small);
      border-bottom: 5px solid transparent;
      display: flex;
      flex: 1;
      flex-direction: column;
    }

    &:hover {
      background-color: $dea-subcategory-content-hover-color;
      color: $dea-subcategory-icon-hover-color;

      .categories-list__icon-container svg {
        @include colorize-subcategory-icon($dea-subcategory-icon-hover-color);
      }

      .categories-list__item-wrapper {
        border-color: $dea-subcategory-icon-hover-color;
      }

      .categories-list__area-name {
        color: $dea-subcategory-icon-hover-color;
      }
    }
  }

  &__icon-container {
    @include hmargin(auto, auto);
    margin-top: auto;
    width: 55%;

    svg {
      @include colorize-subcategory-icon($dea-subcategory-content-color);
    }
  }

  &__area-name {
    color: $dea-subcategory-content-color;
    text-align: center;

    &-container {
      margin-top: $space-x-small;
      min-height: $space-base;
      text-transform: uppercase;
    }
  }
}

.dea-event-wrapper {
  .dea-event__icon svg {
    @include colorize-subcategory-icon($dea-subcategory-content-color);
  }
}
