@import "deascuola-design-system/dist/system/tokens/tokens";
@import "deascuola-design-system/src/styles/styles";

@import "grid/00.grid.settings";
@import "grid/01.grid.generator";
@import "grid/02.grid.utilities";
//@import 'grid/99.grid.debug'; // Uncomment for debug check

// This needs to be imported in DS
@import "ds";

@import "00_settings/global";
@import "01_tools/mixins";
@import "02_elements/variables.formazione";
@import "03_components/icons";
@import "03_components/look_feel";
@import "03_components/search";
@import "03_components/lanci";
@import "03_components/events";
@import "03_components/events_list";
@import "03_components/sidebar";
@import "03_components/wysiwyg";
@import "03_components/error";
@import "03_components/categories";
@import "03_components/widget-video";
@import "03_components/content-page";
@import "03_components/manuals";
@import "03_components/contact-form";
@import "03_components/home";
@import "03_components/header";
@import "03_components/slider";
@import "03_components/banner-ie";
@import "03_components/responsive_launches";
@import "03_components/footer";
@import "03_components/menu";
@import "03_components/top-header";

@import "shame_ds_components";
@import "shame";
@import "ticket";

body > header {
  &.site-header {
    display: none;
  }
}
