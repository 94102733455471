// TODO: Port to DS
.products {
  margin-bottom: $space-large;
  margin-top: $space-large;

  &__filters {
    .dea-checkbox-search-list + .dea-checkbox-search-list {
      margin-top: $space-large;
    }
  }

  &__filters-title {
    margin: $space-base 0;
  }

  &__header {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: $space-large;

    h1 {
      @include media("<=s") {
        margin-bottom: $space-small;
        text-align: center;
        width: 100%;
      }

      margin: 0;
    }

    .products__filter-container {
      align-items: flex-end;
      display: flex;
      flex-direction: column;

      label {
        font-family: $font-primary;
        font-size: $h-font-size-xx-small;
        margin-bottom: $space-x-small;
        text-transform: uppercase;
      }
    }
  }

  &__filter-box {
    @include media("<=s") {
      width: 100%;
    }

    position: relative;
    width: $dea-product-input-box;

    &-label {
      @include h-font-size-xx-small;
      font-family: $font-primary-medium;
      margin-bottom: $space-x-small;
      text-transform: uppercase;
    }

    input {
      @include media("<=s") {
        padding-right: $space-base;
      }

      padding: $space-small 0 $space-small $space-x-small;
      width: 100%;
    }

    i {
      position: absolute;
      right: $space-x-small;
      top: 50%;
      transform: translateY(-50%);

      &::before {
        vertical-align: middle;
      }
    }
  }

  &__list {
    .dea-product-card {
      margin-bottom: $space-large;

      &--book {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

.dea-product-card--book .dea-product-card__image {
  background-repeat: no-repeat;
  background-size: contain;
}

.dea-product-card__badge {
  z-index: 10;
}

.dea-head-product__head {
  .dea-head-product__cover {
    width: auto;

    img {
      display: block;
      height: auto;
      margin: 0 auto;
      max-width: 100%;
      text-align: center;
      width: auto;
    }
  }
}

@include media("<m") {
  .products__sidebar {
    margin-bottom: $space-large;
    padding-left: $gutter / 2;

    .dea-list-catalog__label,
    .dea-list-catalog__main-list {
      display: none;
      visibility: hidden;
    }
  }

  .products .dea-breadcrumbs {
    display: none;
  }

  .bookwrapper {
    margin-top: $space-base;
  }

  .dea-head-product__head {
    .dea-head-product__cover {
      &:before {
        content: none;
      }

      &-container__inner {
        .dea-product-card__badge {
          bottom: 10%;
          right: -8%;
          top: inherit;
        }
      }
    }
  }
}

.dea-product-card {
  &--book {
    .dea-product-card__image {
      background-repeat: no-repeat;
      background-size: contain;
    }

    &__badge {
      z-index: 10;
    }
  }
}

@include media("<s") {
  .dea-product-card--book {
    margin-bottom: $space-xx-small;
  }
}

@include media("<=xs") {
  .dea-product-card--book {
    width: 100%;
  }
}

.dea-head-product__head {
  @include media("<l") {
    margin-bottom: $space-x-large;
  }

  @include media("<m") {
    margin-bottom: $space-base;
  }

  margin-bottom: $space-xx-large;
}
