/**
 * Add horizontal margin to elements.
 *
 * @mixin
 * @param $sx the margin left value. Default value is `auto`
 * @param $dx the margin right value. Default value is `auto`
 * @example
 *  @include hmargin(3px);
 *  @include hmargin(3px, 4px);
 */
@mixin hmargin($sx: auto, $dx: auto) {
  margin-left: $sx;
  margin-right: $dx;
}

/**
 * Add vertical margin to elements.
 *
 * @mixin
 * @param $top the margin top value. Default value is `auto`
 * @param $bottom the margin bottom value. Default value is `auto`
 * @example
 *  @include vmargin(3px);
 *  @include vmargin(3px, 4px);
 */
@mixin vmargin($top: auto, $bottom: auto) {
  margin-bottom: $bottom;
  margin-top: $top;
}

/**
 * Add horizontal padding to elements.
 *
 * @mixin
 * @param $sx the padding left value. Default value is `auto`
 * @param $dx the padding right value. Default value is `auto`
 * @example
 *  @include hmargin(3px);
 *  @include hmargin(3px, 4px);
 */
@mixin hpadding($sx: auto, $dx: auto) {
  padding-left: $sx;
  padding-right: $dx;
}

/**
 * Add vertical padding to elements.
 *
 * @mixin
 * @param $top the margin left value. Default value is `auto`
 * @param $bottom the margin right value. Default value is `auto`
 * @example
 *  @include hmargin(3px);
 *  @include hmargin(3px, 4px);
 */
@mixin vpadding($top: auto, $bottom: auto) {
  padding-bottom: $bottom;
  padding-top: $top;
}

// Private mixin

@mixin h-like($fontsize) {
  color: $black;
  font-family: $main-font;
  font-size: $fontsize;
  font-weight: bold;
  line-height: $line-height-headings;
  margin-bottom: $gutter / 2;
  word-wrap: break-word;
}

@mixin icon-circle() {
  align-items: center;
  background: none;
  border: $border-light $dea-color-grey-dark;
  border-radius: $border-radius-circle;
  display: flex;
  height: $dea-size-large;
  justify-content: center;
  padding: 0;
  width: $dea-size-large;

  &:before {
    font-size: $dea-size-medium;
  }
}

@mixin icon-square() {
  align-items: center;
  background: none;
  border: $border-light $dea-color-grey-dark;
  border-radius: $border-radius-medium;
  display: flex;
  height: $dea-size-large;
  justify-content: center;
  padding: 0;
  width: $dea-size-large;

  &:before {
    font-size: $dea-size-medium;
  }
}

// Undocumented staff... TODO VVV

%image-vertical-aligned {
  &:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}

/// Placeholder to center element content using flexbox.
%centered-content {
  align-items: center;
  display: flex;
  justify-content: center;
}

// Carlo TODO consider removing this
%display-flex {
  display: flex;
}

@mixin flex-rounded-image($image-width) {
  flex: 0 0 $image-width;
  height: $image-width;
  width: $image-width;

  img {
    border: $border-light $dea-color-target-magenta-formazione;
    border-radius: $border-radius-circle;
    width: 100%;
  }
}

@mixin title-with-border() {
  header h2 {
    border-bottom: $border-light $dea-color-grey-medium;
    line-height: $line-height-small;
    width: 100%;

    span {
      border-bottom: $space-xx-small solid;
      padding: $space-xx-small;
      padding-bottom: 0.5px;
    }
  }
}

@mixin stylize-event($color: $dea-color-grey-dark) {
  header h2 span {
    border-color: $color;
  }

  .wysiwyg {
    ul li:before {
      color: $color;
    }

    span {
      &.highlighted-text {
        background-color: $color;
      }
    }
  }

  .big-dotted-lists .wysiwyg {
    ul li:before {
      font-size: $h-font-size-x-large;
      line-height: $font-size-large;
      vertical-align: text-bottom;
    }
  }

  .w-text {
    .custom-icon {
      svg {
        .cls-1,
        .cls-2 {
          fill: $color;
          stroke: $color;
        }
      }
    }

    &--colored-border {
      border-color: $color;
    }
  }

  .w-dea-legs-boxes {
    .dea-leg__info--city {
      color: $color;
    }
  }
}

@mixin stylize-homepage-event-boxes($color: $dea-color-grey-dark) {
  border-color: $color;

  .dea-event-box__title {
    color: $color;
  }

  .dea-event-box__cta {
    background: $color;
    border-color: $color;
  }
}

@mixin colorize-subcategory-icon($color) {
  .cls-1,
  .cls-2,
  .cls-3 {
    fill: $color !important; //sass-lint:disable-line no-important
    opacity: 0.5;
  }

  .cls-2 {
    opacity: 1;
  }

  //sass-lint:disable no-qualifying-elements
  text.cls-2 {
    font-size: 13px;
    font-weight: 800;
    letter-spacing: -0.05em;
  }
  //sass-lint:enable no-qualifying-elements
}

@mixin stylize-lists {
  ul,
  ol {
    font-family: $font-primary;
    list-style: none;
    margin-bottom: $space-base;
    margin-left: $space-base;
    margin-top: 0;
    padding-left: 0;
  }

  li {
    color: $dea-color-grey-dark;
    font-family: $font-primary;
    font-size: $font-size-large;
    line-height: 1.6;
    margin-bottom: $space-xx-small;
    padding-left: $space-small;

    &:before {
      @include hmargin(-$space-base, 16px);
      color: $dea-color-grey-dark;
      content: "\2022";
      margin-left: -$space-base;
    }
  }
}
