.site-header .dea-header--center,
.site-header .dea-header--bottom {
  position: static;
}

.dea-header--bottom__links-container .dea-header--submenu--formazione {
  left: 0;
  top: 147px;
  width: 100%;
}

.dea-header--center.dea-header--center--border-none {
  z-index: 1;
}

.dea-top-header-logo__container .dea-links {
  align-items: center;
  display: flex;
}
