.category-header {
  margin-bottom: $space-large;

  &__presentation {
    position: relative;

    img {
      height: auto;
      width: 100%;
    }

    .dea-lancio img {
      height: inherit;
    }

    .subcategory-banner__header {
      & > div:first-of-type {
        margin-bottom: $space-x-small;
      }

      header {
        margin-bottom: $space-small;
      }
    }

    h2 {
      span {
        @include media("<=s") {
          font-size: $h-font-size-xxx-large;
          line-height: $line-height-small;
        }

        border-bottom: 3px solid $dea-color-target-magenta-formazione;
        font-size: $h-font-size-xxxx-large;
        padding-bottom: $space-xx-small;
      }
    }

    .webinar-select-box {
      @include media("<=s") {
        display: none;
      }

      position: absolute;
      right: 0;
      top: 0;

      label {
        margin: 0;
      }
    }
  }

  .subcategory-banner {
    &__name {
      @include media("<=s") {
        font-size: $h-font-size-xxx-large;
        line-height: $line-height-small;
      }

      border-bottom: 3px solid $dea-color-target-magenta-formazione;
      padding-bottom: $space-xx-small;
    }

    &__icon {
      display: inline-block;
      height: auto;
      margin-right: $space-x-small;
      width: $dea-subcategory-icon-width;

      svg {
        @include colorize-subcategory-icon($dea-color-grey-dark);
      }
    }
  }

  .category__image {
    @include media("<=m") {
      margin-top: $space-small;
      position: inherit;

      > div {
        margin-bottom: 0;

        .w-card {
          margin-bottom: 0;
        }
      }

      img {
        width: auto;
      }
    }

    position: absolute;
    right: 0;
    top: 50%;
  }
}
