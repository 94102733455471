.wysiwyg {
  @include stylize-lists;

  p {
    @include font-size-large;
    font-family: $font-primary;
    line-height: $line-height-small;
    margin: 0 0 $space-base / 2 0;
  }

  img + img {
    margin-left: $space-base / 2;
  }

  li > ul {
    margin-bottom: 0;
  }

  a {
    font-family: $font-primary-medium;
    text-decoration: underline;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}

header {
  h1,
  h2,
  h3 {
    margin-bottom: $space-base / 2;
  }
}
