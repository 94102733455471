.w-embedded-video__iframe-container {
  height: 0;
  padding-bottom: 56.25%; // 16:9 ratio
  position: relative;

  iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.w-video {
  &__video {
    width: 100%;
  }

  .video-js {
    background-color: transparent;
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
  }
}
