.dea-search {
  margin-bottom: $space-x-large;
  width: 100%;

  header {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $space-base;
  }

  &__title {
    flex: 1;
    margin: 0;
  }
}

.search-app {
  @include media("<=m") {
    margin-bottom: 0;
  }

  margin-bottom: $space-large;

  &__header {
    background: $dea-color-grey-light;
    box-shadow: inset 0 -1px 0 0 $dea-color-grey-medium-light;
    margin-bottom: $space-large;
    padding-top: $space-large;

    form {
      @include media("<=s") {
        max-width: 80%;
      }

      @include media("<=xs") {
        max-width: 100%;
      }

      max-width: 50%;
      width: 100%;
    }

    [type="text"] {
      padding-left: $space-large;
    }

    .dea-input__icon {
      color: $dea-color-grey-medium;
      font-size: $dea-size-medium;
      left: $space-small;
      right: auto;
    }

    hr {
      margin: $space-large 0;
    }

    h2 {
      margin: 0 0 $space-large 0;
    }

    .dea-tabs {
      background: $dea-color-grey-light;
      padding-left: 0;
    }

    .dea-tabs-single__label {
      @include media("<=s") {
        width: 100%;
      }
      background: $dea-color-grey-light;
    }

    .dea-tabs-single__input:checked + .dea-tabs-single__label {
      background: $dea-color-white;
    }

    .dea-tabs-single__badge {
      background: $dea-color-grey-medium-light;
      color: $dea-color-black;
    }
  }

  &__reset {
    background: $dea-color-grey-medium-light;
    border: 0;
    border-radius: 100%;
    position: absolute;
    right: $space-small;
    top: 50%;
    transform: translateY(-50%);

    .dea-button__icon:before {
      color: $dea-color-grey-dark;
      fill: $dea-color-grey-dark;
    }
  }

  .dea-pager {
    flex-wrap: wrap;
    margin-top: $space-large;

    &__button {
      margin-bottom: $space-x-small;
    }
  }
}

.dea-list-catalog__main-list__item-active {
  .dea-border-target-color-magenta-formazione {
    border-left: $border-bold $dea-color-target-magenta-formazione !important; // sass-lint:disable-line no-important
  }
}

.dea-list-catalog__section-title {
  &.dea-border-target-color-magenta-formazione {
    border: 0;
  }
}

.pac-item-query {
  @include p_font-size-medium; // sass-lint:disable-line mixin-name-format
}

.search-app-no-results {
  &__title {
    @include h_font-size-large; // sass-lint:disable-line mixin-name-format
    font-family: $font-primary-medium;
  }

  &__message {
    @include h_font-size-large; // sass-lint:disable-line mixin-name-format
  }

  i {
    font-size: $dea-size-large;
  }
}

.category-detail {
  @include media("<=m") {
    padding-top: $space-base;
  }

  padding-top: $space-large;

  .xs-toggle {
    @include media("<=m") {
      width: 100%;
    }

    width: 75%;
  }
}

.main-content {
  min-height: 100vh;

  .products {
    padding-top: $space-large;
  }
}
