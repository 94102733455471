.homepage {
  .dea-hero {
    @include media("<m") {
      height: 304px;
    }
    margin-bottom: 0;

    &__payoff {
      @include media("<m") {
        margin-bottom: $space-x-large;
      }
      @include media("<s") {
        @include hpadding(0, 0);
      }
      @include hmargin(auto, auto);
      margin-bottom: $space-xx-large * 3;
    }
  }

  &__header {
    margin-bottom: $space-base;

    &-content {
      @include media("<m") {
        margin-top: 0;
        padding-top: $space-base;
      }
      margin-top: -20%;

      &-container {
        background: $dea-color-grey-light;
      }
    }
  }

  &__search {
    background: $dea-color-grey-dark;
    opacity: 0.9;
    padding: $space-small;

    .dea-header--center__search-container {
      @include media("<m") {
        margin-left: 0;
      }

      &:before {
        left: 0;
        position: absolute;
        transform: translate(0, 2px);
      }
    }

    .dea-header--center__search--transparent-header {
      border-bottom-color: $dea-color-white;
      font-weight: $font-weight-bold;
    }
  }

  // This box with border could lead to a strange 1px white space between the button and the bottom border.
  // If we move the button OUTSIDE the .wrapper div in homepage_event_box.html, and set the .dea-event-box
  // border-bottom to 0, the button will take ALL the box horizontal space but this bug will disappear.
  // This comment is here just to point out the solution to an eventual request from their side to roll back
  // this change. If they want to roll back, they'll have to pay dearly.
  &__boxes {
    @include vmargin($space-base, $space-base);
    position: relative;

    .grid > * {
      display: flex;
    }

    .dea-event-box {
      background: $dea-color-white;
      border: $border-bold transparent;
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-bottom: $space-base;
      text-align: center;

      &__wrapper {
        @include media("<m") {
          padding: $space-base $space-base 0 $space-base;
        }
        @include media("<xs") {
          padding: $space-base $space-large 0 $space-large;
        }
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
        padding: $space-base $space-large 0 $space-large;
      }

      &__title {
        @include media("<s") {
          font-size: $font-size-x-large;
        }
        @include media("<xs") {
          font-size: $h-font-size-x-large;
        }
        border-bottom: $border-bold;
        font-family: $font-primary-extra-bold;
        font-weight: $font-weight-bold;
        margin: 0 auto;
        padding-bottom: $space-x-small;
        width: 90%;
      }

      &__text {
        @include vmargin($space-base, $space-small * 2);

        p {
          font-size: $font-size-small;
        }
      }

      &__cta {
        background: transparent;
        border: $border-bold transparent;
        color: $dea-color-white;
        display: block;
        margin-top: auto;
        padding: 6px;
        width: 100%;

        span {
          margin-left: $space-xx-small;

          &:before {
            color: $dea-color-white;
            font-size: $h-font-size-large;
            vertical-align: middle;
          }
        }
      }

      &.convention {
        @include stylize-homepage-event-boxes($dea-event-convention);
      }

      &.seminar {
        @include stylize-homepage-event-boxes($dea-event-seminar);
      }

      &.attended-event {
        @include stylize-homepage-event-boxes($dea-color-secondary-magenta);
      }

      &.customizable-course {
        @include stylize-homepage-event-boxes($dea-event-customizable-course);
      }

      &.webinar {
        @include stylize-homepage-event-boxes($dea-event-webinar);
      }

      &.video-lesson {
        @include stylize-homepage-event-boxes($dea-event-video-lesson);
      }

      &.online-course {
        @include stylize-homepage-event-boxes($dea-event-online-course);
      }

      &.bundle {
        @include stylize-homepage-event-boxes($dea-event-bundle);
      }
    }
  }

  &__ministerial-claim {
    @include media("<xs") {
      flex-direction: column;
    }
    display: flex;
    justify-content: space-between;
    padding-bottom: $space-large;

    &--text {
      @include media("<xs") {
        flex: 1;
      }
      @include media("<s") {
        flex: 0 0 40%;
      }
      flex: 0 0 60%;

      p {
        line-height: $line-height-small;
        margin: 0;
      }
    }

    &--image-container {
      @include media("<xs") {
        flex: 1;
        margin-top: $space-base;
      }
      @include media("<s") {
        flex: 0 0 55%;
      }
      display: flex;
      flex: 0 0 35%;
      justify-content: space-between;
    }

    &--image {
      align-items: center;
      display: flex;
      flex: 0 0 48%;

      img {
        height: auto;
        width: 100%;
      }
    }
  }

  &__content {
    // This is the beginning for various grid fixes that shouldn't be there
    .wrapper-content {
      padding: 0 $gutter-half;
    }

    .full-width-content {
      @include media("<m") {
        padding: 0 $gutter-half;
      }
    }
  }

  .dea-search {
    @include vmargin($space-large, 0);

    .wrapper-content {
      // This too, shouldn't be there
      @include hpadding(0, 0);
    }

    .grid {
      // This MUST go away, in a very near future, please
      width: calc(100% + #{$gutter});
    }
  }

  .w-dea-speakers {
    margin-top: $space-large;
  }

  .w-dea-manuals {
    margin-top: $space-large;
    width: 100%;

    .grid {
      // This MUST go away, in a very near future, please
      width: calc(100% + #{$gutter});
    }

    .dea-product-card {
      &--book {
        @include media("<=l") {
          width: 100%;
        }
      }

      &__image-container {
        @include media("<=l") {
          height: 312px;
        }
      }

      &__image {
        @include media("<=l") {
          height: 232px;
          width: 178px;
        }
      }
    }

    &__header {
      @include media("<=xs") {
        display: block;
      }
      align-items: center;
      display: flex;
      justify-content: space-between;
      position: relative;

      h2 {
        margin-top: 0;
      }
    }

    &__see-all-container {
      @include media("<=xs") {
        margin-bottom: $space-small;
        position: initial;
      }
      position: absolute;
      right: 0;
      top: $space-x-small;
    }
  }

  .w-section {
    margin-bottom: $space-large;
  }

  .dea-feed {
    margin-top: $space-large;
    width: 100%;

    &__header {
      text-align: center;
    }

    .dea-live-card {
      margin-bottom: $space-base;
    }
  }
}
