.contact-form {
  &__mobile-message {
    @include media("<=s") {
      display: block;
    }

    display: none;
  }

  &__header {
    margin-top: $space-large;
  }

  &__container {
    .contact-form__back-link {
      @include media("<=s") {
        margin-bottom: 0;
        margin-top: $space-large;
      }
    }
  }

  &__form {
    @include media("<=s") {
      margin: $space-base 0 0 0;
      max-width: 100%;
    }
    margin: $space-large 0 $space-x-large $space-large;
    max-width: 360px;
    width: 100%;
  }

  &__success {
    p {
      margin-bottom: $space-base;
    }

    .dea-input__icon {
      font-size: $font-size-large;
      height: 36px;
      position: static;
      width: 36px;
    }
  }

  &__sidebar {
    @include media("<=s") {
      @include vmargin($space-base, $space-base);
    }
    margin-top: $space-x-large;

    .dea-button {
      justify-content: left;
      margin-top: $space-small;

      &--large-text {
        .dea-button__label {
          font-family: $font-primary-bold;
          font-size: $font-size-medium;
        }

        .dea-button__icon {
          &-align-right {
            margin-left: auto;
          }

          &:before {
            font-size: $dea-size-large;
          }
        }
      }

      & + .dea-button {
        margin-top: $space-base;
      }
    }
  }

  .grid {
    @include media("<=s") {
      flex-direction: column-reverse;

      > :first-child {
        order: 1;
      }
    }
  }

  .dea-input--small .dea-input__icon {
    bottom: 10px;
  }

  .w-text {
    margin-bottom: 0;
  }

  .dea-ico-email-sent {
    color: $dea-color-grey-dark;
    font-size: $dea-size-large;
  }

  .checkbox-container {
    @include media("<=s") {
      justify-content: inherit;

      .dea-checkbox + .dea-checkbox {
        margin-left: $space-base;
      }
    }
    @include vmargin($space-base, 0);
    display: flex;
    justify-content: space-between;
  }

  .dea-checkbox__label {
    @include media("<=s") {
      max-width: 100%;
    }

    a {
      text-decoration: underline;
    }
  }

  .form-field--internal_comunications,  //sass-lint:disable-line class-name-format
  .form-field--external_comunications,  //sass-lint:disable-line class-name-format
  .form-field--analysis {
    .dea-checkbox__label {
      &.underline {
        display: inline;
        text-decoration: underline;
      }

      &.semi-bold {
        display: inline;
        font-family: $font-primary-medium;
      }
    }
  }
}

.form-field {
  + #{&} {
    margin-top: $space-base;
  }
}

.tpl-naked {
  .main-content {
    padding-top: 0;
  }
}

.share-form {
  .dea-input textarea {
    max-height: 160px;
  }
}

.privacy-title {
  @include vmargin($space-small, $space-small);
}
