$event-detail-image-max-width: 640px;
$ministerial-logo-width: 82px;

.dea-links--event.dea-links {
  .dea-links__label {
    border-color: $dea-color-target-magenta-formazione;
    font-family: $font-primary-medium;
  }
}

.event-detail {
  .dea-links--event.dea-links {
    .dea-links__label {
      border-color: $dea-color-target-magenta-formazione;
      font-family: $font-primary-medium;
    }
  }

  .event-video {
    position: relative;

    video {
      display: block;
      height: auto;
      width: 100%;
    }

    .dea-ico-close {
      position: absolute;
      right: $space-small;
      top: $space-small;

      &:before {
        color: $dea-color-grey-dark;
      }
    }
  }

  .event-video-iframe-container {
    height: 0;
    padding-bottom: 56.25%; // 16:9 ratio
    position: relative;

    iframe {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .event-header {
    @include media("<=xs") {
      background-image: none !important; //sass-lint:disable-line no-important
    }
    background: $dea-event-header-background;
    background-position: 50% 50%;
    background-size: cover;
    padding-top: $space-small;

    &__logo {
      height: auto;
      max-width: $event-detail-image-max-width;
      width: 100%;
    }

    &__small-logo {
      align-self: flex-start;
      width: 21%;
    }

    &__group-label {
      margin-bottom: $space-x-small;
    }

    &__breadcrumbs {
      margin-bottom: $space-base;

      span:before {
        display: inline-block;
        font-size: $dea-size-small;
        transform: translateY($space-xx-small);
      }

      &--icons {
        display: flex;
      }
    }

    &__description {
      @include media("<=s") {
        display: none;
      }
      margin-bottom: 0;
      word-break: break-word;

      &--mobile {
        @include media("<=s") {
          display: block;
        }
        display: none;
      }

      &--always-visible {
        @include media("<=s") {
          display: block;
        }
      }
    }

    &__button-back-to-event {
      @include media("<s") {
        justify-content: flex-end;
      }

      @include vpadding(0, $space-base);
      align-items: flex-end;
      display: flex;
      height: 100%;

      .dea-button {
        background-color: $dea-color-white;
      }
    }

    &__categories {
      display: flex;

      .icon {
        background: $dea-event-header-categories-background;
        border: 0;
      }

      > * + * {
        margin-left: $space-x-small;
      }

      &--icon {
        width: 60%;

        svg {
          @include colorize-subcategory-icon($dea-color-grey-dark);
        }
      }
    }

    &__icons {
      @include media("<=s") {
        flex-direction: column;

        > .icon.dea-button--square + .icon.dea-button--square {
          margin-left: 0;
          margin-top: $space-x-small;
        }
      }

      > * + * {
        margin-left: $space-x-small;
      }

      display: flex;
      margin-left: auto;
    }

    &__title-section {
      @include media("<=s") {
        @include vmargin($space-base, $space-base);
      }
      @include vmargin($space-large, $space-x-large);

      h1 {
        margin-top: 0;
      }

      &--flex {
        @include media("<=s") {
          @include vmargin($space-base, $space-base);
        }
        @include vmargin($space-large, $space-large);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &.event-header__title-section--flex-small {
          @include vmargin(0, $space-x-small);
          justify-content: flex-start;

          .event-header__small-logo {
            @include hmargin(0, $space-base);
            width: 14%;
          }
        }
      }
    }

    &__title-container {
      width: 75%;

      h1 {
        @include media("<=s") {
          font-size: $h-font-size-xx-large;
        }
        margin-top: 0;
      }
    }

    &__ministerial-container {
      @include media("<=s") {
        width: 100%;
      }
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding-bottom: $space-small;
      width: 70%;
    }

    &__ministerial-text,
    &__sofia-text {
      margin-right: $space-small;

      p {
        @include h-font-size-xx-small; // sass-lint:disable-line mixin-name-format
        font-family: $font-primary-medium;
        margin-bottom: 0;
      }
    }
  }

  &--convention .event-content .event-content__placeholder-container {
    @include stylize-event($dea-event-convention);
  }

  &--seminar .event-content .event-content__placeholder-container {
    @include stylize-event($dea-event-seminar);
  }

  &--attended-event .event-content .event-content__placeholder-container {
    // This is different from the others because they asked for a different color
    // and so we can't use $dea-event-attended-event color
    @include stylize-event($dea-color-secondary-magenta);
  }

  &--customizable-course .event-content .event-content__placeholder-container {
    @include stylize-event($dea-event-customizable-course);
  }

  &--webinar .event-content .event-content__placeholder-container {
    @include stylize-event($dea-event-webinar);
  }

  &--video-lesson .event-content .event-content__placeholder-container {
    @include stylize-event($dea-event-video-lesson);
  }

  &--online-course .event-content .event-content__placeholder-container {
    @include stylize-event($dea-event-online-course);
  }

  &--bundle .event-content .event-content__placeholder-container {
    @include stylize-event($dea-event-bundle);
  }

  .event-content {
    @include title-with-border();

    .w-dea-show-more-plugin {
      .dea-links--show-more,
      .w-dea-show-more-plugin__content {
        @include hmargin(-$gutter-half, -$gutter-half);
      }
    }

    .event-faq-link {
      @include media(">=s") {
        display: none;
      }

      margin-top: $space-base;

      button {
        margin-bottom: $space-base;
      }
    }

    .event__next-legs-anchor--mobile {
      display: none;
      margin-top: $space-base;
    }

    .dea-partners__container,
    .dea-collaborations__container {
      @include media("<=s") {
        &:after {
          content: "";
          flex: 0 0 45%;
        }
      }
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: $space-base;

      &:after {
        content: "";
        flex: 0 0 30%;
      }

      img {
        @include media(">s") {
          &:nth-child(n + 4) {
            margin-top: $space-base;
          }
          width: 30%;
        }
        @include media("<=s") {
          &:nth-child(n + 3) {
            margin-top: $space-base;
          }
          width: 45%;
        }
      }
    }

    .w-dea-targets {
      &__header {
        @include media("<=m") {
          display: block;
        }
        align-items: center;
        display: flex;
        margin-bottom: $space-base;
        position: relative;

        h2 {
          margin: 0;
        }
      }

      &__error {
        @include media("<=m") {
          margin-left: 0;
          margin-top: $space-x-small;
          position: initial;
        }
        bottom: $space-small;
        position: absolute;
        right: 0;
      }
    }

    .w-dea-testimonials {
      &__header {
        @include media("<=xs") {
          display: block;
        }
        align-items: center;
        display: flex;
        justify-content: space-between;
        position: relative;

        h2 {
          margin-top: 0;
        }
      }

      &__see-all-container {
        @include media("<=xs") {
          margin-bottom: $space-small;
          position: initial;
        }
        position: absolute;
        right: 0;
        top: $space-x-small;
      }

      &__see-all-link {
        .dea-links__icon {
          font-size: $dea-size-small;
          margin-left: $space-x-small;
          transform: translateY($space-xx-small);
        }
      }

      &__slider {
        @include media("<=s") {
          @include vpadding($space-base, $space-base);
        }
        @include vpadding($space-large, $space-large);

        .slick-track {
          display: flex;
        }
      }

      &__content {
        @include media("<=s") {
          flex-direction: column;
        }
        @include hpadding($space-large, $space-large);
        @include vpadding(0, 0);
        align-self: center;
        display: flex;
      }

      &__image-container {
        @include media("<=s") {
          @include hmargin(auto, auto);
        }
        @include vmargin(0, 0);
        @include flex-rounded-image($testimonial-picture-size);
      }

      &__text-container {
        flex-grow: 1;
      }

      &__description {
        @include font-size-x-large;
        font-family: $font-primary;
        line-height: $line-height-small;
        margin-top: 0;
        text-align: center;
      }

      &__signature {
        margin-bottom: 0;
        text-align: right;
      }
    }

    .w-dea-questions {
      &__header {
        @include media("<=xs") {
          display: block;
        }
        align-items: center;
        display: flex;
        justify-content: space-between;
        position: relative;

        h2 {
          margin-top: 0;
        }
      }

      &__content {
        padding-top: $space-base;
      }

      &__signature {
        text-align: right;
      }

      &__see-all-container {
        @include media("<=xs") {
          margin-bottom: $space-small;
          position: initial;
        }
        position: absolute;
        right: 0;
        top: $space-x-small;
      }

      &__see-all-link {
        .dea-links__icon {
          font-size: $dea-size-small;
          margin-left: $space-x-small;
          transform: translateY($space-xx-small);
        }
      }

      &__speaker {
        @include media("<=s") {
          @include hmargin(0, 0);
          display: block;
          margin-bottom: $space-xx-small;
        }
        @include hmargin($space-xx-small, $space-x-small);
        color: $dea-color-grey-dark;
        display: inline-block;
        position: relative;

        &--name {
          cursor: pointer;
          line-height: normal;
        }
      }

      .dea-tooltip {
        left: 0;

        &.active {
          display: block;
        }

        &__inner {
          @include media("<=m") {
            width: $tooltip-width-tablet;
          }
          @include media("<=xs") {
            width: $tooltip-width-phone;
          }
        }
      }
    }

    .w-dea-gallery {
      &__container {
        @include media("<=m") {
          content: "";
          flex: 0 0 48%;
        }
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &:after {
          content: "";
          flex: 0 0 31%;
        }
      }

      &__image {
        @include media(">m") {
          &:nth-child(n + 4) {
            margin-top: $space-base;
          }
          flex: 0 0 31%;
        }
        @include media("<=m") {
          &:nth-child(n + 3) {
            margin-top: $space-base / 2;
          }
          flex: 0 0 48%;
        }
        @include media("<=xs") {
          flex: 0 0 100%;
          margin-top: $space-base / 2;
        }

        &:nth-child(n + 4) {
          display: none;
        }
        align-self: center;

        img {
          height: auto;
          width: 100%;
        }
      }

      &__see-all-container {
        margin-top: $space-small;
        text-align: right;
      }

      &__see-all-link {
        .dea-links__icon {
          font-size: $dea-size-small;
          transform: translateY($space-xx-small);
        }
      }
    }

    .w-dea-previous-editions {
      &__container {
        @include media("<=m") {
          &:after {
            flex: 0 0 48%;
          }
        }
        @include media("<=xs") {
          &:after {
            flex: 0 0 100%;
          }
        }

        &:after {
          content: "";
          flex: 0 0 31%;
        }

        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      &__link {
        @include media(">m") {
          &:nth-child(n + 4) {
            margin-top: $space-base;
          }
        }
        @include media("<=m") {
          &:after {
            flex: 0 0 48%;
          }
          flex: 0 0 48%;
          margin-bottom: $space-base;
        }
        @include media("<=xs") {
          &:after {
            flex: 0 0 100%;
          }
          flex: 0 0 100%;
          margin-bottom: $space-base / 2;
        }
        @include font-size-x-large;
        @include vpadding($space-small * 2, $space-small * 2);
        background: $dea-event-header-background;
        flex: 0 0 31%;
        font-family: $font-primary-bold;
        line-height: $line-height-small;
        text-align: center;
      }
    }

    .w-dea-schedule {
      &__header {
        @include media("<=s") {
          display: block;
        }
        align-items: baseline;
        display: flex;
        justify-content: space-between;
        position: relative;

        h2 {
          margin-top: 0;
        }
      }

      &__row {
        @include media("<=s") {
          display: block;
        }
        align-items: baseline;
        display: flex;
        margin-bottom: $space-base;
      }

      &__left-column {
        flex: 15%;
      }

      &__right-column {
        @include media("<=xs") {
          position: relative;
        }
        flex: 85%;

        i {
          @include media("<=xs") {
            display: block;
            position: absolute;
            right: 0;
            top: $space-xxx-small;
          }
          display: none;
        }
      }

      &__title {
        @include media("<=xs") {
          display: block;
          padding-right: $space-base;

          &--container--clickable {
            cursor: pointer;
          }
        }
      }

      &__time,
      &__title {
        margin: 0;
      }

      &__download {
        &-container {
          @include media("<=s") {
            margin-bottom: $space-small;
            position: initial;
          }
          position: absolute;
          right: 0;
          top: $space-xx-small;
        }

        &-link {
          color: $dea-color-grey-dark;
        }
      }

      &__description {
        @include media("<=xs") {
          display: none;

          &.active {
            display: block;
          }
        }
        margin-top: $space-xx-small;

        p {
          @extend .dea-heading--regular__xm;
          margin: 0;
        }
      }

      &__speaker {
        @include media("<=s") {
          @include hmargin(0, 0);
          display: block;
          margin-bottom: $space-xx-small;
        }
        @include hmargin($space-xx-small, $space-x-small);
        color: $dea-color-grey-dark;
        display: inline-block;
        position: relative;

        &--name {
          cursor: pointer;
          line-height: normal;
        }
      }

      .dea-tooltip {
        left: 0;

        &.active {
          display: block;
        }

        &__inner {
          @include media("<=m") {
            width: $tooltip-width-tablet;
          }
          @include media("<=xs") {
            width: $tooltip-width-phone;
          }
        }
      }
    }

    .w-dea-laboratories {
      &__header {
        @include media("<=s") {
          display: block;
        }
        align-items: baseline;
        display: flex;
        justify-content: space-between;
        position: relative;

        h2 {
          margin-top: 0;
        }
      }

      &__filter {
        @include media("<=s") {
          position: initial;
        }
        position: absolute;
        right: 0;
        top: -$space-xx-small;
      }

      &__container {
        display: none;

        &.active {
          display: block;
        }
      }

      &__title {
        cursor: pointer;
        letter-spacing: $letter-spacing-large;
        text-transform: uppercase;

        i {
          font-size: $dea-size-small;
          vertical-align: sub;

          &:before {
            transition: ease-in-out 0.5s;
          }
        }
      }

      &__laboratory {
        &--header {
          @include media("<=xs") {
            display: block;
          }
          align-items: baseline;
          display: flex;
          justify-content: space-between;
        }

        &--targets {
          @include media("<=xs") {
            float: none;
          }
          float: right;
        }

        &--title {
          @include vmargin(0, $space-xx-small);
        }

        &--description p {
          @extend .dea-heading--regular__xm;
          margin-top: 0;
        }

        &--speakers {
          @extend .dea-heading--medium__xm;
          margin-top: $space-base;
        }

        &--restricted {
          @include media("<=xs") {
            text-align: left;
          }
          @include vmargin(0, $space-x-small);
          color: $dea-color-target-magenta-formazione;
          text-align: right;
        }

        &:last-child {
          margin-bottom: $space-base * 2;
        }
      }
    }

    .w-dea-legs-boxes {
      &__header {
        @include media("<=s") {
          display: block;
        }
        align-items: baseline;
        display: flex;
        justify-content: space-between;
        margin-bottom: $space-x-small;
        position: relative;

        h2 {
          margin-top: 0;
        }
      }

      &__search {
        @include media("<=s") {
          position: initial;
        }
        position: absolute;
        right: 0;
        top: -$space-xx-small;
      }

      &__container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &:after {
          @include media("<=m") {
            content: "";
            flex: 0 0 48%;
          }

          content: "";
          flex: 0 0 32%;
        }

        &--previous-edition {
          &:after {
            @include media("<=m") {
              flex: 0 0 48%;
            }

            content: "";
            flex: 0 0 24%;
          }

          &.w-dea-legs-boxes--reminder-2 {
            &:after {
              flex: 0 0 49%;
            }
          }
        }
      }

      &--previous-edition {
        @include vmargin($space-large, $space-xx-large);
      }

      .dea-leg {
        @include media(">m") {
          &:nth-child(n + 4) {
            margin-top: $space-base;
          }
          flex: 0 0 31%;

          &__previous-edition {
            &:nth-child(n + 4) {
              margin-top: 0;
            }

            &:nth-child(n + 5) {
              margin-top: $space-base;
            }

            flex: 0 0 24%;

            button {
              height: auto;
            }

            a + a {
              margin-top: $space-small;
            }
          }
        }
        @include media("<=m") {
          &:nth-child(n + 3) {
            margin-top: $space-base;
          }
          flex: 0 0 48%;
        }
        @include media("<=xs") {
          flex: 0 0 100%;
          margin-top: $space-base;
        }

        &__previous-next:nth-child(n + 7) {
          display: none;
        }
      }

      &__see-more {
        margin-top: $space-base;
        text-align: right;

        &-link {
          cursor: pointer;

          &.active {
            .dea-links__icon {
              transform: rotate(180deg);
            }
          }
        }
      }
    }

    .w-dea-bundles {
      &__container {
        @include media("<=m") {
          content: "";
          flex: 0 0 48%;
        }
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &:after {
          content: "";
          flex: 0 0 31%;
        }
      }

      .dea-event__link {
        @include media(">m") {
          flex: 0 0 31%;
        }
        @include media("<=m") {
          flex: 0 0 48%;
        }
        @include media("<=xs") {
          flex: 0 0 100%;
        }
      }
    }

    &__placeholder-container > * {
      margin-top: $space-large;
    }

    &__partners {
      .partner {
        &__image {
          width: 100%;
        }
      }
    }

    .w-text {
      .custom-icon {
        align-items: center;
        display: flex;

        svg {
          width: $dea-size-large;
        }

        p {
          margin: $space-x-small + $space-xx-small 0 0 $space-x-small;
        }
      }

      &--colored-border {
        border: $border-bold $dea-color-grey-medium;
        padding: $space-base;

        h2 {
          margin-top: 0;
        }
      }
    }

    &__placeholder-container > * {
      margin-top: $space-large;
    }
  }

  &--old-edition {
    .event-header__breadcrumbs {
      margin-bottom: 0;
    }

    .event-header__title-section {
      margin-bottom: $space-base;

      h1 {
        margin-bottom: 0;
        margin-top: $space-base;
      }

      .event-header__small-logo {
        align-self: flex-end;
      }
    }
  }
}

.dea-tooltip--event {
  .dea-tooltip {
    display: none;
    position: absolute;
    z-index: 1;

    &__inner {
      @include media("<=m") {
        width: $tooltip-width-tablet;
      }
      @include media("<=s") {
        width: $tooltip-width-phone;
      }
      width: $tooltip-width-desktop;
    }

    &__content p {
      @include font-size-small;
      line-height: $line-height-base;
    }
  }
}

.event-footer {
  &__header {
    margin-bottom: $space-small;
  }

  .dea-event-wrapper {
    .dea-event__link {
      height: 100%;
    }
  }
}

.event-footer,
.manual-footer {
  padding: $space-large 0;
}

.dea-questions {
  &__qa-container {
    @include vpadding($space-large, $space-large);
  }
}

.dea-comment {
  @include vpadding($space-x-small, $space-x-small);
}

.dea-event-slider {
  background: $dea-event-header-background;
}

.w-dea-speakers {
  width: 100%;

  &__slider {
    @include vpadding($space-base, $space-base);
  }

  &__slide {
    outline: none;
  }

  &__card {
    background: white;
    border: $border-light;
    border-radius: $border-radius-large;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    min-height: $card-size;
    padding: $space-x-small;
    width: $card-size;
  }

  &__image-container {
    @include flex-rounded-image($speaker-picture-size);
  }

  &__title {
    @include font-size-large;
    font-family: $font-primary-medium;
  }

  .w-dea-speaker__content {
    margin-bottom: $space-small;
  }
}

.w-dea-speaker,
.w-dea-testimonials {
  &__content {
    @include media("<=s") {
      display: block;
    }
    align-items: center;
    background: $dea-event-header-background;
    display: flex;
    padding: $space-small;
  }

  &__image-container {
    @include flex-rounded-image($speaker-picture-size);
  }

  &__text-container {
    @include media("<=s") {
      margin-left: 0;
    }
    margin-left: $space-base;
  }

  &__name {
    @include font-size-large;
    font-family: $font-primary-medium;
    margin-top: 0;
  }

  &__description p {
    @include font-size-large;
    font-family: $font-primary;
    line-height: $line-height-small;
    margin-bottom: 0;

    & + p {
      margin-top: $space-base / 2;
    }
  }
}

.w-dea-speaker {
  width: 100%;

  &__content {
    align-items: flex-start;
    width: 100%;
  }

  &__image-container {
    @include media("<=s") {
      margin-bottom: $space-small;
    }
  }
}
