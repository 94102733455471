$badge-container-width: calc(100% - #{$space-x-small});
$badge-container-padding-bottom: calc(#{$space-x-small} + #{$space-xxx-small});

// _modals.scss

.fullpage-modal-toggle,
.fullpage-modal__close {
  display: none;
}

@include media("<m") {
  .fullpage-modal-toggle {
    display: block;
    margin-top: $space-base;
  }

  .fullpage-modal {
    background: $dea-color-white;
    height: 100%;
    left: 0;
    opacity: 0;
    overflow-y: scroll;
    padding: $gutter / 2;
    padding-bottom: $space-xx-large;
    padding-top: 90px;
    pointer-events: none;
    position: fixed;
    top: 0;
    transition: opacity 0.2s;
    width: 100%;
    z-index: 999;

    &.active {
      opacity: 1;
      pointer-events: auto;
    }

    &__title {
      background: $dea-color-white;
      box-shadow: $box-shadow-light;
      color: $dea-color-black;
      display: block !important; // sass-lint:disable-line no-important
      font-family: $font-primary-medium;
      font-size: $font-size-x-large;
      height: 72px;
      left: 0;
      line-height: 72px;
      margin: 0;
      padding: 0 $space-small;
      position: fixed;
      text-transform: none;
      top: 0;
      visibility: visible !important; // sass-lint:disable-line no-important
      width: 100%;
      z-index: 9999;
    }

    &__close {
      background-color: $dea-color-white;
      border: 0;
      cursor: pointer;
      display: block;
      min-width: auto;
      position: fixed;
      right: 0;
      top: $space-small;
      z-index: 9999;
    }
  }
}

.dea-modal-overlay {
  background-color: rgba($dea-color-black, 0.8);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999999;

  &--paypal {
    display: none;

    &.active {
      display: block;
    }
  }
}

.dea-modal {
  @include media("<s") {
    width: 100%;
  }

  padding: 0;
  width: 80%;

  &__title-container {
    @include media("<m") {
      padding: $space-base;
    }

    margin-bottom: 0;
    padding: $space-large;
  }

  &__footer {
    background-color: $dea-color-grey-light;
    padding: $space-base $space-large;
  }

  .share-actions {
    display: flex;

    .dea-social-logo + .dea-social-logo {
      margin-left: $space-x-small;
    }
  }

  .spacer {
    flex: 1;
  }

  &__content {
    @include media("<m") {
      padding: 0 $space-base;
    }

    padding: 0 $space-large;
  }

  iframe {
    border: 0;
    display: block;
    width: 100%;
  }

  &__actions {
    margin-bottom: $space-base;

    > * + * {
      margin-left: $space-base;
    }
  }

  &__cancel-button > .dea-button__label {
    pointer-events: none;
  }
}
