$dea-header-height: 50px; /* Base height of top header - desktop */
$dea-header-mobile-height: 67px; /* Base height of top header - mobile */
$dea-header-main-height: 73px; /* Base height of main header - desktop */
$dea-header-main-mobile-height: 70px; /* Base height of main header with in page menu - mobile */

.header-extra-buttons--slot {
  background-color: $dea-color-white;
  display: flex;
  height: 100%;

  .dea-links__label {
    border-bottom: 0;
    text-align: center;
  }
}

.site-header + .main-content {
  margin-top: auto;
}

.shopping-cart-container {
  position: relative;

  .dea-header--top__button.dea-header--top__button--border-right {
    border-left: 0;
    border-right: $border-light $dea-color-grey-light;
  }
}

.dea-header-menu__item--grey {
  background: $dea-color-grey-light;
  border-color: $border-light $dea-color-grey-light;
}

.dea-header--bottom__button {
  .dea-links {
    align-items: baseline;
  }
}

.dea-header--extra-button__links {
  align-items: center;
  border-bottom: $border-bold transparent;
  display: flex !important; //sass-lint:disable-line no-important
  height: 100%;
  margin: 0 $space-small;
}

.site-header {
  .dea-header--bottom--formazione {
    @include media("<=l") {
      display: block;
    }

    align-items: flex-end;
    border-bottom: 0;
    display: flex;
    height: auto;
    width: 100%;
  }

  .dea-header--bottom {
    @include media("<=l") {
      display: none !important; //sass-lint:disable-line no-important
    }

    border-bottom: 0;
    display: flex;
    justify-content: end;
  }

  .dea-header--center {
    @include media("<=l") {
      height: $dea-header-main-mobile-height;
    }
    height: auto;
    padding-left: 0;

    &.dea-header--center--border-none {
      border-bottom: 0;
    }
  }

  .dea-header--submenu {
    margin-top: $space-xxx-small;
  }

  .mobile-menu {
    @include media("<=l") {
      display: block;
    }

    .mobile-menu-trigger {
      @include media("<=l") {
        display: block;
      }
    }

    &.active {
      @include media("<=l") {
        box-shadow: 0 0 0 2000px rgba($dea-color-black, 0.8);
        z-index: 8;

        .mobile-menu__container,
        .mobile-menu__back,
        .mobile-menu__close {
          color: $dea-color-white;
          display: block;
          pointer-events: auto;
        }

        .mobile-menu-trigger {
          display: none;
        }

        .mobile-menu__container {
          max-height: $dea-mobile-menu-container-max-height;
          overflow-y: auto;
        }
      }
    }
  }
}
