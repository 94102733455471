.slick-arrow {
  @include media("<=s") {
    top: 50%;
  }
  outline: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.slick-prev {
  @include media("<=m") {
    left: -$space-x-small;
  }
  @include media("<s") {
    left: -$space-xx-small;
  }
  left: -$space-x-small;
  transform: translateY(-50%) rotate(180deg);
}

.slick-next {
  @include media("<=m") {
    right: -$space-x-small;
  }
  @include media("<s") {
    right: -$space-xx-small;
  }
  right: -$space-x-small;
}
