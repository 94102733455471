.site-header {
  @include media(">m") {
    margin-top: 1px;
  }

  position: static !important; //sass-lint:disable-line no-important
  width: 100%;
  z-index: 90;

  .dea-header--top {
    @include media("<=m") {
      display: flex;
    }

    &__left {
      @include media("<=m") {
        display: none;
      }
    }

    &__right {
      @include media("<=m") {
        margin-left: auto;
      }

      & > .dea-header--top__button {
        @include media("<m") {
          display: none;
        }

        padding: 0 $space-base !important; //sass-lint:disable-line no-important
      }

      .shopping-cart {
        @include media("<=xs") {
          bottom: 0;
          left: 0;
          overflow: auto;
          position: fixed;
          right: 0;
          top: 0;
          width: 100%;
        }
      }
    }
  }
}
