/** MENU **/

.visually-hidden {
  height: 0;
  overflow: hidden;
}

.side-menu {
  margin: 0;
  padding: 0;

  &__item {
    list-style: none;
    margin: 0;
    padding: 0;

    &--border-target-color-magenta-formazione {
      > a {
        border-left: $border-bold $dea-color-target-magenta-formazione;
      }
    }
  }

  &__separator {
    margin: 0 $gutter-half;
  }
}

.main-footer {
  background: $dea-color-grey-medium;
  height: 500px;
}

.dea-navigation__button {
  margin: $space-base 0;
}

.dea-header--center__menu {
  @include media("<m") {
    display: block !important; //sass-lint:disable-line no-important
  }
  cursor: pointer;

  display: none !important; //sass-lint:disable-line no-important
}
